
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '../../utils/decorators'
@Component({
  name: 'planList'
})
@KeepAlive
export default class List extends Vue {
  private searchInfo = {
    introduce: '',
    projectName: '',
    planMonth: this.$dayjs().format('YYYY-MM')
  }

  private summaryinfo = {}

  // private clickSearchParams = [{
  //   introduce: '',
  //   projectName: '',
  //   planMonth: this.$dayjs().format('YYYY-MM')
  // }] as any // 点击搜索按钮时的参数

  private loading = false
  private tableData = []
  private page = 1
  private size = 10
  private total = 0

  get customerCode () {
    return sessionStorage.getItem('customerCode') || ''
  }

  created () {
    // console.log('listCreate')
    // this.searchInfo.planMonth = this.$dayjs().format('YYYY-MM')
    this.getData()
    this.getSummaryData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.plan.selectFlowPlansByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  getSummaryData () {
    this.$axios.get(this.$apis.plan.selectFlowPlansSum, {
      customerCode: this.customerCode,
      ...this.searchInfo
    }).then((res) => {
      this.summaryinfo = res
    })
  }

  goSummaryList (taskStatus: any, isFinished: any) {
    // console.log('去列表', taskStatus, isFinished)
    this.$router.push({
      query: {
        // introduce: this.clickSearchParams[this.clickSearchParams.length - 1].introduce,
        // projectName: this.clickSearchParams[this.clickSearchParams.length - 1].projectName,
        // planMonth: this.clickSearchParams[this.clickSearchParams.length - 1].planMonth
        introduce: this.searchInfo.introduce,
        projectName: this.searchInfo.projectName,
        planMonth: this.searchInfo.planMonth,
        taskStatus,
        isFinished
      },
      path: '/plan/taskDetails'
    })
  }

  onAdd () {
    this.$router.push({ name: 'planAdd' })
  }

  onDetail (id: string) {
    this.$router.push({ name: 'planDetail', params: { id } })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.plan.deleteFlowPlans, {
        planId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      })
    })
  }

  onSearch () {
    // this.clickSearchParams.push(this.searchInfo)
    this.page = 1
    this.getData()
    this.getSummaryData()
  }
}
